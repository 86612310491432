<template>
  <div class="row bg-white px-lg-16 px-md-13">
    <div class="col-lg-10 col-md-10 p-5 px-lg-16 px-md-4 pt-0">
      <div
        class="accordion accordion-solid accordion-toggle-plus"
        id="accordionFaq"
      >
        <div
          :key="'faq-' + indexCategory"
          v-for="(faq, mainCategory, indexCategory) in faqs"
        >
          <h3 :id="'category' + indexCategory" class="text-center pt-3">
            {{ mainCategory }}
          </h3>
          <div :key="subCategory" v-for="(data, subCategory, indexSub) in faq">
            <h5 :id="'sub' + indexCategory + indexSub" class="pt-4 pb-2">
              {{ subCategory }}
            </h5>

            <div
              class="card m-3"
              :key="'question' + id"
              v-for="(item, id) in data"
            >
              <div class="card-header" :id="'heading' + id">
                <div
                  class="card-title collapsed py-3 pr-10 font-weight-normal"
                  data-toggle="collapse"
                  :data-target="'#collapse' + id"
                >
                  {{ item.question }}
                </div>
              </div>
              <div
                :id="'collapse' + id"
                class="collapse"
                data-parent="#accordionFaq"
              >
                <div class="card-body">
                  {{ item.answer }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2">
      <b-container v-if="isAdmin" fluid class="text-center p-2">
        <b-row>
          <b-col>
            <v-btn
              color="primary"
              rounded
              dark
              size="sm"
              :loading="isSelecting"
              @click="handleFileImport"
            >
              Upload FAQ
            </v-btn>

            <input
              ref="uploader"
              class="d-none"
              type="file"
              @change="onFileChanged"
            />
          </b-col>
        </b-row>
      </b-container>

      <div class="list-group position-fixed">
        <div
          :key="mainCategory"
          v-for="(faq, mainCategory, indexCategory) in faqs"
        >
          <a
            class="list-group-item list-group-item-action pt-1 pb-1"
            :href="'#category' + indexCategory"
            ><small
              ><b>{{ mainCategory }}</b></small
            ></a
          >
          <div :key="subCategory" v-for="(data, subCategory, indexSub) in faq">
            <a
              class="list-group-item list-group-item-action pt-1 pb-1"
              :href="'#sub' + indexCategory + indexSub"
              ><small>{{ subCategory }}</small></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postFile } from "@/api/help/faq/getters";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  props: {
    faqs: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isSelecting: false,
      selectedFile: null
    };
  },

  computed: {
    ...mapGetters(["isAdmin"])
  },

  methods: {
    handleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];

      this.submitFile();

      // Do whatever you need with the file, liek reading it with FileReader
    },
    submitFile() {
      /*
              Initialize the form data
          */
      let formData = new FormData();

      /*
          Add the form data we need to submit
      */
      formData.append("faq", this.selectedFile);

      /*
        Make the request to the POST /single-file URL
      */
      postFile(formData, axios);
    }
  }
};
</script>

<style>
.accordion.accordion-toggle-plus .card .card-header .card-title {
  color: black;
}

.accordion.accordion-toggle-plus .card .card-header .card-title.collapsed {
  color: black;
}
</style>
