import { get as getV2, post as postV2 } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "@/api/config/api2.0";

export const getFaq = (axios, language) =>
  getV2(
    {
      path: `${ENTITY_NAME_IN_URI_V2.HELP.FAQ.path}`,
      ressources: [
        {
          name: language
        }
      ]
    },
    {},
    axios
  );

export const postFile = (body, axios) =>
  postV2(
    {
      path: ENTITY_NAME_IN_URI_V2.HELP.FAQ_UPLOAD.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI_V2.HELP.FAQ_UPLOAD.name
        }
      ]
    },
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    },
    body,
    axios
  );
