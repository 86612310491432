<template>
  <div>
    <Desktop :faqs="faqs"></Desktop>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { getFaq } from "@/api/help/faq/getters";
import axios from "axios";
import Desktop from "@/views/pages/help/Desktop";

export default {
  name: "faq",

  components: {
    Desktop
  },

  data() {
    return {
      loading: false,
      faqs: {}
    };
  },

  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Help",
        pageCategory: "Frequently Asked Questions"
      }
    ]);
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const [language] = navigator.language.split("-");
      this.loading = true;
      this.faqs = await getFaq(axios, language);
    }
  }
};
</script>
